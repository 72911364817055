<script setup lang="ts">
  import { ref, watch } from 'vue'
  import { useAppStore } from '@/stores/app'

  const translations: Record<string, { home: string, program: string, event: string, partner: string, gallery: string, about: string }> = {
    en: {
      home: 'Home',
      program: 'Program',
      event: 'Blogs',
      partner: 'Partner',
      gallery: 'Gallery',
      about: 'About Us',
    },
    id: {
      home: 'Beranda',
      program: 'Program',
      event: 'Blog',
      partner: 'Mitra',
      gallery: 'Galeri',
      about: 'Tentang Kami',
    },
  }

  const currentLanguage = ref<'en' | 'id'>('id')
  const app = useAppStore()

  watch(
    () => app.selectedLanguage,
    (newLanguage) => {
      if (newLanguage && ['id', 'en'].includes(newLanguage)) {
        currentLanguage.value = newLanguage
      }
    },
    { immediate: true },
  )

  if (app.selectedLanguage && ['id', 'en'].includes(app.selectedLanguage)) {
    currentLanguage.value = app.selectedLanguage
  }
</script>

<template>
  <router-link :to="{ name: 'home' }">
    {{ translations[currentLanguage].home }}
  </router-link>
  <router-link :to="{ name: 'program' }">
    {{ translations[currentLanguage].program }}
  </router-link>
  <router-link :to="{ name: 'event list' }">
    {{ translations[currentLanguage].event }}
  </router-link>
  <router-link :to="{ name: 'partner' }">
    {{ translations[currentLanguage].partner }}
  </router-link>
  <router-link :to="{ name: 'gallery' }">
    {{ translations[currentLanguage].gallery }}
  </router-link>
  <router-link :to="{ name: 'about' }">
    {{ translations[currentLanguage].about }}
  </router-link>
</template>

<style lang="postcss" scoped>
  a {
    @apply block;
    @apply sm:focus-visible:ring-white;
  }
  .router-link-active {
    @apply font-bold;
  }
</style>