<script setup lang="ts">
  import AppSocialIcons from '@/components/app-social-icons.vue'
  import { useAppStore } from '@/stores/app'

  const app = useAppStore()
</script>

<template>
  <footer class="bg-primary-500 text-white">
    <div class="container py-2 flex flex-wrap gap-x-12 gap-y-2 items-center justify-between">
      <app-social-icons class="text-lg gap-x-2" />
      <div class="text-sm">
        &copy; {{ new Date().getFullYear() }} {{ app.COMPANY_NAME }}
      </div>
    </div>
  </footer>
</template>