<script setup lang="ts">
  import { watch } from 'vue'
  import TheMenu from './the-menu.vue'

  const props = defineProps<{
    show: boolean,
  }>()

  const emit = defineEmits<{
    (e: 'close'): void,
  }>()

  const close = () => {
    emit('close')
  }

  // prevent body scroll when sidebar open
  watch(() => props.show, (show) => {
    const body = document.querySelector('body')
    if (body) {
      if (show) {
        body.classList.add('overflow-y-hidden')
      } else {
        body.classList.remove('overflow-y-hidden')
      }
    }
  })
</script>

<template>
  <div>
    <transition name="fade">
      <button
        v-if="show"
        class="fixed inset-0 bg-black bg-opacity-50 z-10"
        @click="close"></button>
    </transition>
    <transition name="slide">
      <div
        v-if="show"
        :class="[
          'fixed inset-y-0 w-48 bg-gray-100 z-10 transition-all right-0',
        ]">
        <div class="text-right py-8 px-4 text-gray-400">
          <button class="focus-visible:ring-blue-200" @click="close">
            <fa-icon icon="fas xmark" />
          </button>
        </div>
        <div class="divide-y px-2">
          <the-menu />
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="postcss" scoped>
  :deep() {
    a {
      @apply p-2 hover:bg-gray-300 focus-visible:ring-blue-200 text-gray-600;
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    @apply transition duration-200;
  }

  .fade-enter-from,
  .fade-leave-to {
    @apply opacity-0;
  }

  .slide-enter-active,
  .slide-leave-active {
    @apply transition-all duration-200;
  }

  .slide-enter-from,
  .slide-leave-to {
    @apply -right-48;
  }
</style>