<script setup lang="ts">
  import { useAbout } from '@/models/about'
  import { useAppStore } from '@/stores/app'
  import TheFooter from '@/views/the-footer.vue'
  import TheNavbar from '@/views/the-navbar.vue'

  const app = useAppStore()
  useAbout()
  const handleGoogleTranslateSelect = (language: { code: 'en' | 'id' }) => {
    app.selectedLanguage = language.code
  }
</script>

<template>
  <div class="relative max-w-[1920px] mx-auto bg-white">
    <the-navbar @select="handleGoogleTranslateSelect" />
    <div class="min-h-screen flex flex-col">
      <div class="flex-1">
        <router-view v-slot="{Component}">
          <transition name="fade">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
      <the-footer />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
  .fade-enter-active,
  .fade-leave-active {
    @apply transition duration-200;
  }

  .fade-enter-from,
  .fade-leave-to {
    @apply opacity-0;
  }
</style>