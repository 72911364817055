import './assets/style.css'
import GoogleTranslateSelect from '@google-translate-select/vue3'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import App from './App.vue'
import globalDirectives from './directives'
import router from './routes'
import fontAwesome from './services/font-awesome'
import VueSmoothScroll from 'vue3-smooth-scroll'

const pinia = createPinia()
createApp(App)
  .use(pinia)
  .use(router)
  .use(globalDirectives)
  .use(fontAwesome)
  .use(VueSmoothScroll)
  .use(GoogleTranslateSelect)
  .mount('#app')
