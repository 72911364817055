import { ref } from 'vue'
import api from '@/functions/api'
import { useAppStore } from '@/stores/app'

export function useAbout () {
  const app = useAppStore()
  const about = ref<About>()
  const loading = ref(false)

  const getData = async () => {
    try {
      loading.value = true
      const response = await api().GET<Response<About>>('about')
      if (response.data.length > 0) {
        about.value = response.data[0]
        app.setAbout(response.data[0])
      }
    } catch (error) {
    } finally {
      loading.value = false
    }
  }

  getData()

  return {
    getData,
    about,
    loading,
  }
}