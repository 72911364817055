<script setup lang="ts">
  import { onBeforeUnmount, onMounted, ref, watch } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useAppStore } from '@/stores/app'
  import TheMenu from './the-menu.vue'
  import TheMobileSidebar from './the-mobile-sidebar.vue'

  const app = useAppStore()
  const route = useRoute()
  const router = useRouter()

  const transparentUntil = 1
  const isTransparent = ref(true)
  const showSidebar = ref(false)

  const setNavbarTransparency = () => {
    isTransparent.value = window.pageYOffset <= transparentUntil
      && ['home', 'about'].includes(String(route.name))
  }
  onMounted (() => {
    window.addEventListener('scroll', setNavbarTransparency)
  })
  onBeforeUnmount (() => {
    window.removeEventListener('scroll', setNavbarTransparency)
  })
  watch(() => route.fullPath, () => {
    showSidebar.value = false
    isTransparent.value = false
    setTimeout(setNavbarTransparency, 200)
  })
  router.isReady().then(() => {
    isTransparent.value = false
    setTimeout(setNavbarTransparency, 200)
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const props = defineProps({
    defaultLanguageCode: String,
    defaultPageLanguageCode: String,
    fetchBrowserLanguage: Boolean,
    languages: Array,
    trigger: String,
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const emit = defineEmits(['select'])
</script>

<template>
  <div
    class="fixed inset-x-0 top-0 z-10 bg-primary-500 max-w-[1920px] mx-auto notranslate"
    :class="{
      'bg-transparent': isTransparent,
      'bg-primary-500': !isTransparent,
    }">
    <div class="container text-white">
      <div class="flex justify-between items-center h-navbar">
        <img
          src="@/assets/images/bbf-logo-no-bg.png"
          :alt="app.COMPANY_NAME"
          class="h-12 sm:h-full sm:py-2">
        <div class="hidden sm:flex space-x-8 sm:space-x-6 text-white items-center text-center">
          <the-menu />
        </div>
        <div class="ml-auto sm:ml-0">
          <google-translate-select
            default-language-code="id"
            default-page-language-code="id"
            :fetch-browser-language="true"
            :languages="[
              { code: 'id', name: 'Indonesia' },
              { code: 'en', name: 'English' },
            ]"
            trigger="click"
            @select="$emit('select', $event)" />
        </div>
        <button class="sm:hidden" @click="showSidebar = true">
          <fa-icon icon="fas bars" />
        </button>
      </div>
    </div>
  </div>
  <the-mobile-sidebar :show="showSidebar" class="sm:hidden" @close="showSidebar = false" />
</template>

<style lang="postcss" scoped>
  .notranslate {
    translate: none;
  }
</style>